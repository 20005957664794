/**
 * Copyright Compunetix Incorporated 2016-2019
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
/**
 * SDP util to modify sdp
 */
import { IRTCClient } from "../services/rtc.client.interface";
import { Browser } from "./browser";
import { VideoResolution } from "../settings/video-resolution";
import { EasyRTCService } from "../services/rtc.service.easy";

export class MediaUtil {
  static recorders: any = {};
  /**
   * Builds the constraint object
   * @returns {Object} mediaConstraints
   */
  static getUserMediaConstraints(
    rtcClient: IRTCClient,
    isForScreen: boolean = false,
    isAudioEnabled: boolean = true,
    isVideoEnabled: boolean = true,
    isForPrimaryCamera: boolean = true, 
    resolution: VideoResolution = isForPrimaryCamera ? rtcClient.currentSysPrimaryResolution : rtcClient.currentSysSecondaryResolution
  ): any {
    var constraints: any = Object();
    constraints.audio = this.getAudioMediaConstraints(rtcClient, isForScreen, isAudioEnabled);
    constraints.video = this.getVideoMediaConstraints(rtcClient, isForScreen, isVideoEnabled, isForPrimaryCamera, resolution);
    if (isForScreen) {
      constraints.cursor = "always";
    }
    return constraints;
  }
  /**
   * Builds the audio constraint object
   * @returns {Object} mediaConstraints
   */
  static getAudioMediaConstraints(rtcClient: IRTCClient, isForScreen: boolean = false, isEnabled: boolean = true): any {
    var constraints: any = Object();
    if (!isEnabled) {
      constraints = false;
    } else {
      if (rtcClient.audioSrcId && !isForScreen) {
        constraints.deviceId = { exact: rtcClient.audioSrcId };
      }
    }
    return constraints;
  }
  /**
   * Builds the video constraint object
   * @returns {Object} mediaConstraints
   */
  static getVideoMediaConstraints(
    rtcClient: IRTCClient,
    isForScreen: boolean = false,
    isEnabled: boolean = true,
    isForPrimaryCamera: boolean = true,
    resolution: VideoResolution = isForPrimaryCamera ? rtcClient.currentSysPrimaryResolution : rtcClient.currentSysSecondaryResolution
  ): any {
    var constraints: any = Object();
    if (!isEnabled) {
      constraints = false;
      return constraints;
    }
    if (isForScreen) {
      if ((navigator.mediaDevices as any).getDisplayMedia) {
        constraints = Object();
        if (rtcClient.screenSourceid) {
          constraints.chromeMediaSourceId = rtcClient.screenSourceid;
        }
        if (rtcClient.PRESENTATION_SHARPNESS_FIRST_RESOLUTION) {
          constraints.width = {
            max: rtcClient.PRESENTATION_SHARPNESS_FIRST_RESOLUTION.width,
            ideal: rtcClient.PRESENTATION_SHARPNESS_FIRST_RESOLUTION.width
          };
          constraints.height = {
            max: rtcClient.PRESENTATION_SHARPNESS_FIRST_RESOLUTION.height,
            ideal: rtcClient.PRESENTATION_SHARPNESS_FIRST_RESOLUTION.height
          };
        }
        constraints.frameRate = {
          ideal: rtcClient.PRESENTATION_MOTION_FIRST_FRAMERATE
        };
      } else if (Browser.whichBrowser() === "Chrome") {
        constraints.mandatory = Object();
        constraints.mandatory.chromeMediaSource = "desktop";
        if (rtcClient.screenSourceid) {
          constraints.mandatory.chromeMediaSourceId = rtcClient.screenSourceid;
        }
        if (rtcClient.PRESENTATION_SHARPNESS_FIRST_RESOLUTION) {
          constraints.mandatory.maxWidth = rtcClient.PRESENTATION_SHARPNESS_FIRST_RESOLUTION.width;
          constraints.mandatory.maxHeight = rtcClient.PRESENTATION_SHARPNESS_FIRST_RESOLUTION.height;
        }
        constraints.mandatory.maxFrameRate = rtcClient.PRESENTATION_MOTION_FIRST_FRAMERATE;
      } else {
        constraints.mediaSource = "screen";
        if (rtcClient.PRESENTATION_SHARPNESS_FIRST_RESOLUTION) {
          constraints.width = {
            max: rtcClient.PRESENTATION_SHARPNESS_FIRST_RESOLUTION.width,
            ideal: rtcClient.PRESENTATION_SHARPNESS_FIRST_RESOLUTION.width
          };
          constraints.height = {
            max: rtcClient.PRESENTATION_SHARPNESS_FIRST_RESOLUTION.height,
            ideal: rtcClient.PRESENTATION_SHARPNESS_FIRST_RESOLUTION.height
          };
        }
        constraints.frameRate = { max: rtcClient.PRESENTATION_MOTION_FIRST_FRAMERATE,
          ideal: rtcClient.PRESENTATION_MOTION_FIRST_FRAMERATE };
      }
    } else {
      if (isForPrimaryCamera && resolution) {
        constraints.width = {
          max: resolution.width,
          ideal: resolution.width
        };
        constraints.height = {
          max: resolution.height,
          ideal: resolution.height
        };
      }
      if (!isForPrimaryCamera && resolution) {
        constraints.width = {
          max: resolution.width,
          ideal: resolution.width
        };
        constraints.height = {
          max: resolution.height,
          ideal: resolution.height
        };
      }
      if (rtcClient.primaryVideoFrameRate) {
        constraints.frameRate = { max: rtcClient.primaryVideoFrameRate, ideal: rtcClient.primaryVideoFrameRate };
      }
      let videoSrcId = isForPrimaryCamera ? rtcClient.primaryVideoSrcId : rtcClient.secondaryVideoSrcId;
      if (videoSrcId) {
        constraints.deviceId = { exact: videoSrcId };
      }
    }
    return constraints;
  }

  /**
   * apply constraints on media stream
   * @param stream: any - the stream
   * @param rtcClient: IRTCClient - contains config information
   */
  static applyConstraintsOnStream(
    stream: any,
    rtcClient: IRTCClient,
    isForScreen: boolean,
    isForPrimaryCamera: boolean = true,
    isAudioEnabled: boolean = true,
    isVideoEnabled: boolean = true
  ): void {
    if (!stream || !rtcClient) {
      return;
    }
    _.forEach(stream.getAudioTracks(), (audioTrack: any) => {
      let constraints = this.getAudioMediaConstraints(rtcClient, isForScreen, isAudioEnabled);
      if (constraints) {
        audioTrack.applyConstraints(constraints)
          .catch((e) => {
            console.warn("failed to apply constraints:", constraints, "on AudioTrack:", audioTrack, "error:", e)
          });
      }
    });
    _.forEach(stream.getVideoTracks(), (videoTrack: any) => {
      let constraints = this.getVideoMediaConstraints(rtcClient, isForScreen, isVideoEnabled, isForPrimaryCamera);
      if (constraints) {
        videoTrack.applyConstraints(constraints)
          .catch((e) => {
            console.warn("failed to apply constraints:", constraints, "on VideoTrack:", videoTrack, "error:", e)
          });
      }
    });
  }

  /**
   * apply constraints on media stream
   * @param constraints: MediaTrackConstraints - new constraints
   * @param track: MediaStreamTrack - track
   */
  static applyVideoConstraintsOnTrack (constraints: MediaTrackConstraints, track: MediaStreamTrack): Promise<void> {
    return track.applyConstraints(constraints);
  }

  /**
   * update video resolution on media stream
   * @param stream: any - the stream
   * @param resolution: VideoResolution - video resolution
   */
  static updateVideoResolutionOnStream(
    stream: any,
    rtcClient: IRTCClient,
    isForScreen: boolean,
    resolution: VideoResolution,
    isContent: boolean = false,
    isForPrimaryCamera: boolean = true
  ): void {
    // Short this operation for content sharing.
    if (isContent) {return;}
    _.forEach(stream.getVideoTracks(), (videoTrack: any) => {
      let constraints: any = this.getVideoMediaConstraints(rtcClient, isForScreen, true, isForPrimaryCamera);
      constraints.width = {
        max: resolution.width,
        ideal: resolution.width
      };
      constraints.height = {
        max: resolution.height,
        ideal: resolution.height
      };
      if (constraints.mandatory) {
        constraints.mandatory.maxWidth = resolution.width;
        constraints.mandatory.maxHeight = resolution.height;
      }
      delete constraints.deviceId;
      videoTrack.applyConstraints(constraints).catch((e) => console.log("failed to apply constraints", constraints, videoTrack, e));
    });
  }

  /**
   * add stream to peer connection
   * @param stream: any - the stream to add
   * @param peerConnection: any - the peerconnection to add into
   */
  static addStreamToPeerConnection(stream: any, peerConnection: any) {
    var audioTracks = stream.getAudioTracks();
    _.forEach(audioTracks, (track: any) => {
      this.addTrackToPeerConnection(track, stream, peerConnection);
    });
    var videoTracks = stream.getVideoTracks();
    _.forEach(videoTracks, (track: any) => {
      this.addTrackToPeerConnection(track, stream, peerConnection);
    });
  }

  /**
   * remove stream from peer connection
   * @param stream: any - the stream to remove
   * @param peerConnection: any - the peerconnection to remove from
   */
  static removeStreamFromPeerConnection(stream: any, peerConnection: any) {
    var audioTracks = stream.getAudioTracks();
    _.forEach(audioTracks, (track: any) => {
      this.removeTrackFromPeerConnection(track, peerConnection);
    });
    var videoTracks = stream.getVideoTracks();
    _.forEach(videoTracks, (track: any) => {
      this.removeTrackFromPeerConnection(track, peerConnection);
    });
  }

  /**
   * add track to peer connection
   * @param track: any - the track to add
   * @param stream: any - the stream which contains the track
   * @param peerConnection: any - the peerconnection to add into
   */
  static addTrackToPeerConnection(track: any, stream: any, peerConnection: any) {
    let senders = peerConnection.getSenders();
    let matchedSender: any = _.find(senders, (sender: any) => {
      return sender.track && sender.track.id === track.id;
    });
    if (matchedSender) {
      return;
    }
    EasyRTCService.getSharedInstance().rtcClient.alertHandler("add " + track.kind + " track " + track.id);
    try {
      peerConnection.addTrack(track, stream);
    } catch (err) {
      console.error(err);
    }
  }

  /**
   * remove track from peer connection
   * @param track: any - the track to remove
   * @param peerConnection: any - the peerconnection to remove from
   */
  static removeTrackFromPeerConnection(track: any, peerConnection: any) {
    let senders = peerConnection.getSenders();
    let matchedSender: any = _.find(senders, (sender: any) => {
      return sender.track && sender.track.id === track.id;
    });
    if (matchedSender) {
      peerConnection.removeTrack(matchedSender);
    }
  }

  /**
   * add or replace track in peer connection
   * @param newTrack: any - the new track to replace with
   * @param stream: any - the stream which contains the track
   * @param peerConnection: any - the peerconnection to replace in
   * @param sender: RTCRtpSender - the sender to replace in
   */
   static updateTrackInSender(newTrack: any, stream: any, peerConnection: any, sender: any) : Promise <void>{
    if (newTrack) {
      if (sender) {
        if (sender.track !== newTrack) {
          return MediaUtil.replaceTrackInPCSender(newTrack, stream, peerConnection, sender);
        }
      } else {
        MediaUtil.addTrackToPeerConnection(newTrack, stream, peerConnection);
        return Promise.resolve();
      }
    } else {
      if (sender) {
        MediaUtil.removeSenderFromPeerConnection(sender, peerConnection);
        return Promise.resolve();
      }
    }
  }

  /**
   * remove sender from peer connection
   * @param sender: any - the sender to remove
   * @param peerConnection: any - the peerconnection to remove from
   */
  static removeSenderFromPeerConnection(sender: any, peerConnection: any) {
    peerConnection.removeTrack(sender);
  }

  /**
   * replace track in peer connection
   * @param newTrack: any - the new track to replace with
   * @param stream: any - the stream which contains the track
   * @param peerConnection: any - the peerconnection to replace in
   * @param sender: RTCRtpSender - the sender to replace in
   */
  static replaceTrackInPCSender(newTrack: any, stream: any, peerConnection: any, sender: any) : Promise<void> {
    if (sender) {
      if (sender.replaceTrack) {
        sender
          .replaceTrack(newTrack)
          .then(() => {
            EasyRTCService.getSharedInstance().rtcClient.alertHandler(
              "replace " + sender.track.kind + " track " + sender.track.id + " to " + newTrack.id
            );
            return Promise.resolve();
          })
          .catch((e: Error) => {
            EasyRTCService.getSharedInstance().rtcClient.alertHandler(e.message);
            peerConnection.removeTrack(sender);
            try {
              peerConnection.addTrack(newTrack, stream);
              return Promise.resolve();
            } catch (err) {
              console.error(err);
              return Promise.reject(err?.message || err);
            }
          });
      } else {
        peerConnection.removeTrack(sender);
        try {
          peerConnection.addTrack(newTrack, stream);
          return Promise.resolve();
        } catch (err) {
          console.error(err);
          return Promise.reject(err?.message || err);
        }
      }
    }
  }

  /**
   * enable or disable audio tracks of stream
   * @param mediaStream: MediaStream - media stream
   * @param enabled: boolean - set enabled/disabled
   */
  static enableAudioTracks(mediaStream: MediaStream, enabled: boolean) {
    if (mediaStream) {
      let tracks = mediaStream.getAudioTracks();
      if (tracks) {
        _.forEach(tracks, (track: MediaStreamTrack) => {
          track.enabled = enabled;
        });
      }
    }
  }

  /**
   * enable or disable video tracks of stream
   * @param mediaStream: MediaStream - media stream
   * @param enabled: boolean - set enabled/disabled
   */
  static enableVideoTracks(mediaStream: MediaStream, enabled: boolean) {
    if (mediaStream) {
      let tracks = mediaStream.getVideoTracks();
      if (tracks) {
        _.forEach(tracks, (track: MediaStreamTrack) => {
          track.enabled = enabled;
        });
      }
    }
  }

  static startSpeakerTestAudio(stream?: MediaStream, testAudioFileUrl?: string): void {
    let speakerTestStream: MediaStream = stream;
    let rtcClient: IRTCClient = EasyRTCService.getSharedInstance().rtcClient;
    // check audio context ready for testing audio play
    rtcClient.prepareAudioContext();
    if (!rtcClient.audioContext) {
      return;
    }
    if (!speakerTestStream) {
      // create virtual audio element from audio test file
      if (rtcClient.speakerTestAudioSourceElement) {
        rtcClient.speakerTestAudioSourceElement.pause();
      } else {
        rtcClient.speakerTestAudioSourceElement = new Audio();
      }
      // stream audio from virtual audio element into virtual audio context
      if (rtcClient.speakerTestAudioSourceElement && !rtcClient.speakerTestAudioSourceNode) {
        rtcClient.speakerTestAudioSourceNode = rtcClient.audioContext.createMediaElementSource(
          rtcClient.speakerTestAudioSourceElement
        );
      }
      if (!rtcClient.speakerTestAudioDestinationNode) {
        rtcClient.speakerTestAudioDestinationNode = rtcClient.audioContext.createMediaStreamDestination();
      }
      if (rtcClient.speakerTestAudioSourceNode && rtcClient.speakerTestAudioDestinationNode) {
        rtcClient.speakerTestAudioSourceNode.connect(rtcClient.speakerTestAudioDestinationNode);
      }
      // play virtual test audio source
      rtcClient.speakerTestAudioSourceElement.src = testAudioFileUrl || "media/audio_test_file.mp3";
      rtcClient.speakerTestAudioSourceElement.currentTime = 0;
      rtcClient.speakerTestAudioSourceElement.play().catch();
      // map test stream to virtual test audio source
      speakerTestStream = rtcClient.speakerTestAudioDestinationNode.stream;
    }
    // create audio playback element
    if (rtcClient.speakerTestAudioPlayElement) {
      rtcClient.speakerTestAudioPlayElement.pause();
    } else {
      rtcClient.speakerTestAudioPlayElement = new Audio();
    }
    Promise.resolve()
    .then(() => {
      // set output device
      if (rtcClient.selectedSpeakerOption) {
        return EasyRTCService.getSharedInstance().setAudioOutput(
          rtcClient.speakerTestAudioPlayElement,
          rtcClient.selectedSpeakerOption.deviceId
        );
      }
    })
    .then(() => {
      // feed audio playback
      if (rtcClient.speakerTestAudioPlayElement) {
        rtcClient.speakerTestAudioPlayElement.srcObject = speakerTestStream;
      }
      // play audio playbck
      return rtcClient.speakerTestAudioPlayElement.play().catch();
    })
    .catch((error) => {
      console.log("startSpeakerTestAudio - Failed to setup audio output: ", error);
    });
  }

  static stopSpeakerTestAudio() {
    if (!EasyRTCService.getSharedInstance().rtcClient.speakerTestAudioSourceNode) {
      return;
    }
    EasyRTCService.getSharedInstance().rtcClient.speakerTestAudioSourceNode.disconnect();
    if (EasyRTCService.getSharedInstance().rtcClient.speakerTestAudioPlayElement) {
      EasyRTCService.getSharedInstance().rtcClient.speakerTestAudioPlayElement.pause();
      EasyRTCService.getSharedInstance().rtcClient.speakerTestAudioPlayElement.srcObject = null;
    }
  }

  /**
   * stop media track
   */
  static stopTrack(track: MediaStreamTrack) {
    track.stop();
  }

  /**
   * stop media stream
   */
  static stopStream(stream: MediaStream) {
    if (stream) {
      _.forEach(stream.getTracks(), this.stopTrack);
    }
  }

  /**
   * sort resolution values.
   */
  static videoResolutionSortValue(resolution: VideoResolution) {
    return resolution.height * resolution.width + resolution.height;
  }
}

export enum VideoElementEvents {
  loadstart,
  progress,
  suspend,
  abort,
  error,
  emptied,
  stalled,
  loadedmetadata,
  loadeddata,
  canplay,
  canplaythrough,
  playing,
  waiting,
  seeking,
  seeked,
  ended,
  durationchange,
  timeupdate,
  play,
  pause,
  ratechange,
  resize,
  volumechange
}
