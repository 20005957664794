/**
 * Copyright Compunetix Incorporated 2022
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
 
 /**
  * video filter util interface
  */
 export interface IVideoFilter {
    /**
     * original video stream
     */
    stream?: MediaStream;

    /**
     * virtual background image
     */
    virtualBackgroundImage?: string;

    /**
     * is active
     */
    isActive: boolean;

    /**
     * The quality settings on a scale of 1 to 4
     * 1 being perfomance, 4 being highest quality
     */
    qualitySetting: number;

   /**
    * create a new stream by adding filter
    * @param stream: MediaStream - media stream
    */
   createFilteredStream(): Promise<MediaStream>;
 
   /**
    * stop and clean up
    */
   stopFilteredStream();

  // Background Blur Amount
  backgroundBlurAmount: number;

  // Edge Blur Amount
  edgeBlurAmount: number;

}

 /**
 * image segmentation method
 */
export enum SegmentMethod {
  MediaPipe,
  TensorFlow,
}
