/**
 * Copyright Compunetix Incorporated 2017-2025
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { Component, OnInit, ViewChild } from "@angular/core";
import { Companion, Cookie, IRTCClient, IUser, IVideoFilter } from "companion";
import { UserManagementService } from "./user-management/user-management.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Dispatcher, ActionType } from "./shared/services/dispatcher";
import { GroupManagementService } from "./group-management/group-management.service";
import _ from "lodash";
import { SafeHtmlPipe } from "./shared/pipes/safe-html.pipe";
import { AppService } from "./app.service";

@Component({
  selector: "app",
  templateUrl: "app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit {
  appLoaded: boolean = false;
  rtcClient: IRTCClient = Companion.getRTCClient();

  get videoFilter(): IVideoFilter {
    return this.rtcClient.videoFilter;
  }
  constructor(
    private appService : AppService,
    private userManagementService: UserManagementService,
    private groupManagementService: GroupManagementService,
    private router: Router,
    private route: ActivatedRoute,
    protected safeTextPipe: SafeHtmlPipe
  ) {

    const max = 100;
    const min = 1;
    const random = Math.floor(Math.random() * (max - min + 1)) + min;
    Cookie.setCookie("CompanionNumber", random.toString(), 1, "/", null);
    Companion.init();
  }

  ngOnInit() {
    this.init();
  }


  init() {
    this.userManagementService
    .isAuthenticated()
    .then((result: boolean) => {
      if (result) {
        return this.userManagementService.getMyPermissions()
        .then((permissions: any) => {
          return this.userManagementService.getUserWithRolesAndGroupsById(Companion.getUserService().currentUser["_id"])
          .catch((error: Error) => Promise.reject(error));
        })
        .then((user: any) => {
          this.appLoaded = true;
          return Promise.resolve();
        })
        .catch((error: Error) => Promise.reject(error));
      } else {
        Companion.getUserService().currentUser.permissions = {};
        this.appLoaded = true;
        return Promise.resolve();
      }
    })
    .catch((err: Error) => {
      console.error(err);
    });

    this.userManagementService.checkIfDashboardIsEnabled();

    Dispatcher.register(ActionType.OpenDefaultScreen, this.OpenDefaultScreen.bind(this));
    
    Dispatcher.register(ActionType.OpenThemeManager, this.open.bind(this, ["themes"]));
    Dispatcher.register(ActionType.OpenSystemConfiguration, this.open.bind(this, ["config"]));
    Dispatcher.register(ActionType.OpenUserManagement, this.open.bind(this, ["users"]));
    Dispatcher.register(ActionType.OpenRoleManagement, this.open.bind(this, ["roles"]));
    Dispatcher.register(ActionType.OpenGroupManagement, this.open.bind(this, ["groups"]));
    Dispatcher.register(ActionType.OpenDashboard, this.open.bind(this, ["dashboard", "overview"]));
    Dispatcher.register(ActionType.OpenMyAccount, this.open.bind(this, ["my-account"]));
    Dispatcher.register(ActionType.OpenVCC, this.OpenVCC.bind(this));
    Dispatcher.register(ActionType.OpenVCCList, this.open.bind(this, ["vcc", "list"]));
    Dispatcher.register(ActionType.EnterFullScreen, this.enterFullScreen.bind(this));
    Dispatcher.register(ActionType.ExitFullScreen, this.exitFullScreen.bind(this));
    Dispatcher.register(ActionType.ThemeChanged, this.themeChanged.bind(this));
    this.groupManagementService.checkLicense();
  }

  /**
   * enter full screen mode
   */
  enterFullScreen() {
    try {
      var elem: any = document.body;
      if (elem.requestFullscreen) {
        elem.requestFullscreen()
        .catch(() => console.log("Unable to enter fullscreen"));
      }
      if (elem.webkitRequestFullScreen) {
        elem.webkitRequestFullScreen()
        .catch(() => console.log("Unable to enter fullscreen"));
      }
      if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen()
        .catch(() => console.log("Unable to enter fullscreen"));
      }
    } catch (e) {
      // nothing needed here
    }
  }
  /**
   * exit full screen mode
   */
  exitFullScreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    }
    if ((document as any).webkitExitFullscreen) {
      (document as any).webkitExitFullscreen();
    }
    if ((document as any).mozCancelFullScreen) {
      (document as any).mozCancelFullScreen();
    }
  }

  /**
   * open page
   */
  open(routes: string[]) {
      // match the path to the style in the path... 
      // check the first child because this is the parent component that loads the different child component
      var params = this.route.snapshot?.firstChild?.params ? 
        this.route.snapshot?.firstChild?.params : this.route.snapshot.params;
      var stylePath = (params["style"]) ? this.getSafeHtml(params["style"]) : "";
      this.router.navigate(_.concat("/" + stylePath, routes));
  }
  
  getSafeHtml(text: string): string {
    if (text) {
        return this.safeTextPipe.transform(text) as string;
    }
    return "";
  }

  /**
   * open default page
   */
  OpenDefaultScreen(payload: any): void {
    let currentUser: IUser = Companion.getUserService().currentUser;

    if (!currentUser.isAuthenticated) {
      this.open(["portal"]);
    } else {
      this.open(["my-account"]);
    }
  }

  /**
   * open VCC page
   */
  OpenVCC(payload: any): void {
    if (payload && payload.theme) {
      this.router.navigate(["/", payload.theme, "operators"]);
    } else {
      this.open(["operators"]);
    }
  }

  /**
   * Theme changed
   */
  themeChanged(payload : any): void {
    if (payload && payload.theme) {
      // route depending on the interface...
      const commands = this.router.url.split("/");
      if (commands.length > 1) {
        if(commands.includes("vcc"))
        {
          this.router.navigate(["/", payload.theme, "vcc", "list"]);
        }
        else if(commands.includes("operators") || commands.includes("agents"))
        {
          
          this.router.navigate(["/", payload.theme, "operators", payload.langCode]);
        }
      }
    }
  }

  isAuthenticated() : boolean 
  {
    return  Companion.getUserService().currentUser.isAuthenticated;
  }

  loginRequired() : boolean
  {
    return this.appService.getLoginRequired();
  }
}
