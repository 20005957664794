/**
 * Copyright Compunetix Incorporated 2018
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { Injectable } from "@angular/core";
@Injectable()
export class AppService {

  /**
   * if the interface selected for the application requires
   * a login, this should be set when the component loads.
   */
  private loginRequrired : boolean = false;

  /**
   * Set if the interface that is active requires a login.
   */
  public setLoginRequired()
  {
    console.log("Login required");
    this.loginRequrired = true;
  }

  /**
   * If the interface that is active requires a login. 
   * (this doesn't check if we are already logged in, only that a login is required)
   */
  public getLoginRequired()
  {
    return this.loginRequrired;
  }

  constructor() {}
}
