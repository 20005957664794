/**
 * Copyright Compunetix Incorporated 2020
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { AfterViewInit, Component, Input, OnInit } from "@angular/core";
import { Dispatcher, ActionType } from "../shared/services/dispatcher";
import { Alert, AlertLevel } from "companion";
import { NavBarService } from "scripts/layout/nav-bar/nav-bar.service";

@Component({
  selector: "login",
  templateUrl: "./login.component.html"
})
/**
 * login component
 */
export class LoginComponent implements OnInit, AfterViewInit{
  constructor(
    protected navBarService: NavBarService) {
  }

  ngOnInit()
  {
  }

  ngAfterViewInit() {
    console.log("Login required!");
    this.navBarService.LoadEmptyNavBar();
  }
}
