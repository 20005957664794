/**
 * Copyright Compunetix Incorporated 2018-2025
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng, kbender
 */

export interface actionDispatchCb {
  (payload:any):void;
}

/**
 * view action dispatcher
 */
export class Dispatcher {
  private static actionsPool: { [actionId: number]: actionDispatchCb[] } = {};
  
  static dispatch(action: ActionType, payload?: any, key: string = "default") : void {
    if (!this.actionsPool[action] || !this.actionsPool[action][key]) {
      return;
    }
    return this.actionsPool[action][key](payload);
  }
  static dispatchDelay(action: ActionType, payload?: any, key: string = "default", timeout: number = 1 * 1000) {
    let dispatchTimer = setTimeout(() => {
      clearTimeout(dispatchTimer);
      this.dispatch(action, payload, key);
    }, timeout);
  }
  static register(action: ActionType, callback: actionDispatchCb, key: string = "default") {
    this.actionsPool[action] = this.actionsPool[action] || [];
    this.actionsPool[action][key] = callback;
  }
}


export enum ActionType {
  OpenKioskPasscodeModal,
  SubmitPasscode,
  LoadConferenceBeforeJoinNavBar,
  LoadEmptyNavBar,
  LoadGuestNavBar,
  LoadHostUserNavBar,
  LoadVCCGuestAfterJoinNavBar,
  LoadVCCNewData,
  LoadVCCOperatorAfterJoinNavBar,
  LoadVCCListAfterJoinNavBar,
  ToggleSettingsPanel,
  LeaveConference,
  ToggleShareScreen,
  ToggleShareFilesPanel,
  ToggleContentShare,
  ToggleRemoteDesktop,
  ToggleIncomingQueuePanel,
  ToggleOperatorsPanel,
  ToggleChatPanel,
  ToggleMapScreen,
  ToggleRecording,
  ToggleSnapshot,
  ToggleAudio,
  ToggleVideo,
  ToggleVirtualBackground,
  ToggleInspectorPanel,
  ToggleFullScreen,
  ToggleVideoAspect,
  EnterFullScreen,
  ExitFullScreen,
  ToggleLock,
  Lock,
  Unlock,
  OpenDefaultScreen,
  OpenModalDialog,
  HideModalDialog,
  SubmitChangeReason,
  OpenSharedFolder,
  CloseSharedFolder,
  IncreaseNavBarNotification,
  ResetNavBarNotification,
  OpenThemeManager,
  OpenSystemConfiguration,
  OpenUserManagement,
  OpenRoleManagement,
  OpenGroupManagement,
  OpenDashboard,
  OpenMyAccount,
  CloseLoginModal,
  DeleteGroup,
  SubmitGroupForm,
  OpenGroupForm,
  LoadGroupData,
  LoadGroupDataCallback,
  OpenVCC,
  OpenVCCList,
  LoadVCCListData,
  LoadHangingCalls,
  LogOut,
  Loggedin,
  RefreshEndpointList,
  updateEndpointAudioState,
  updateEndpointVideoState,
  putHoldEndpoint,
  Alert,
  connectAll,
  FinishHelperWizard,
  UpdateMenuItem,
  OpenPurgeDataDialog,
  OpenPDFViewer,
  LoadTopBarMenu,
  ResetTopBarNotification,
  OpenNotification,
  OpenUserProfileForm,
  LoadUserData,
  LoadUserProfileData,
  AutoUpdateMenuItems,
  OpenEditProfile,
  OpenEditMessageTemplate,
  OpenDialOutDialog,
  ToggleChatParticipantSelectList,
  ResetUnreadMessageCountOfCurrentRoom,
  ResetMessageHistoryScrollBar,
  ToggleOptimizeForVideo,
  contentShareStart,
  contentShareStop,
  OpenTransferQueueDialog,
  TransferQueueSubmitted,
  LoadGuestCallbackNavBar,
  UpdateSkillSetDisplay,
  ToggleSilenceChatAudioAlerts,
  AddCategorySubskill,
  ThemeChanged
}
