/**
 * Copyright Compunetix Incorporated 2017-2023
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng, kbender
 */
import { VideoResolution } from "../settings/video-resolution";
import { IParameter } from "../form/parameter.interface";
import { ILocalization } from "./localization.interface";
export interface IFlavor {
  style: string;
  language: string;
  mode: string;
  parameters?: { [key: string]: IParameter };

  collectParameterValues(): { [key: string]: any };
  convertToLocalization(): ILocalization;
  toFormGroup(): any;
  updateFlavorByForm(form: any);
  seedDefaultValues(language: string): void;
}

export enum ConferenceMode {
  call_center = "Call Center",
  embed = "Embed"
}

export enum Languages {
  de = "Deutsch",
  en = "English",
  es = "Española",
  fr = "Français",
  it = "Italiana",
  ja = "日本語",
  zh = "中国人"
}

export enum Bandwidth {
  b_1024 = "1024",
  b_768 = "768",
  b_512 = "512",
  b_384 = "384",
  b_256 = "256",
  b_128 = "128"
}
export enum RecordMode {
  disabled,
  local,
  remote,
  both
}

export enum FlavorParameterKey {
  mode_enabled,
  mode_groupId,
  mode_mode,
  page_enabled,
  page_simplified_view_for_mobile_user,
  page_title,
  page_font_color,
  page_font_family,
  page_font_size,
  page_button_prefer_sharp_edge,
  page_background_color,
  page_background_image,
  page_redirect_url_on_leave,
  page_full_screen_mode,
  topnav_enabled,
  topnav_font_color,
  topnav_background_color,
  topnav_language_selector_options,
  topnav_language_selector_default,
  topnav_menu_items,
  login_enabled,
  login_panel_require_login,
  login_panel_font_color,
  login_panel_background_color,
  login_panel_title,
  login_panel_exit_button,
  login_panel_email_field_title,
  login_panel_password_field_title,
  login_panel_username_field_title,
  login_panel_submit_button_text,
  login_panel_submit_button_color,
  login_panel_help_message_1,
  login_panel_help_message_2,
  login_panel_forgot_username_button_text,
  login_panel_forgot_password_button_text,
  login_panel_send_button_text,
  login_panel_cancel_button_text,
  login_panel_openidc_url,
  login_panel_openidc_button,
  login_panel_desktop_only_text,
  login_panel_login_as_available,
  toolbar_enabled,
  toolbar_auto_hide,
  toolbar_font_color,
  toolbar_font_hover_color,
  toolbar_font_active_color,
  toolbar_font_family,
  toolbar_background_color,
  toolbar_border_color,
  toolbar_brand_title_left,
  toolbar_brand_title_right,
  toolbar_brand_title_color,
  toolbar_brand_logo,
  toolbar_brand_logo_aria_label,
  toolbar_items_settings_toggle,
  toolbar_items_enter_toggle,
  toolbar_items_enter_toggle_host_only,
  toolbar_items_lock_toggle,
  toolbar_items_lock_toggle_host_only,
  toolbar_items_chat_toggle,
  toolbar_items_chat_toggle_host_only,
  toolbar_items_incoming_queue_toggle,
  toolbar_items_incoming_queue_toggle_host_only,
  toolbar_items_operators_toggle,
  toolbar_items_operators_toggle_host_only,
  toolbar_items_share_screen_toggle,
  toolbar_items_share_screen_toggle_host_only,
  toolbar_items_content_share_toggle,
  toolbar_items_content_share_toggle_host_only,
  toolbar_items_optimize_for_video_toggle,
  toolbar_items_share_folder_toggle,
  toolbar_items_share_folder_toggle_host_only,
  toolbar_items_inspector_toggle,
  toolbar_items_inspector_toggle_host_only,
  toolbar_items_record_toggle,
  toolbar_items_record_toggle_host_only,
  toolbar_items_map_toggle,
  toolbar_items_map_toggle_host_only,
  toolbar_items_audio_toggle,
  toolbar_items_video_toggle,
  toolbar_items_full_screen_toggle,
  toolbar_items_video_aspect_toggle,
  toolbar_items_dashboard_open,
  toolbar_items_snapshot_toggle,
  toolbar_items_snapshot_toggle_host_only,
  title_screen_enabled,
  title_screen_font_color,
  title_screen_background_color,
  title_screen_title,
  title_screen_logo,
  queue_screen_enabled,
  queue_screen_list,
  connect_screen_enabled,
  connect_screen_font_color,
  connect_screen_font_size,
  connect_screen_top_position,
  connect_screen_background_color,
  connect_screen_title,
  connect_screen_logo,
  connect_screen_logo_size,
  connect_screen_connecting_image,
  connect_screen_subtitle,
  connect_screen_connect_button,
  connect_screen_connect_button_text_color,
  connect_screen_connect_button_background_color,
  connect_screen_connect_panel_bottom,
  connect_screen_connect_panel_background_color,
  connect_screen_connect_panel_waittime_label,
  connect_screen_connect_panel_position_label,
  connect_screen_dropcall_button,
  connect_screen_dropcall_button_text_color,
  connect_screen_dropcall_button_background_color,
  connect_screen_callback_button,
  connect_screen_callback_button_text_color,
  connect_screen_callback_button_background_color,
  connect_screen_callback_phone_number_text,
  connect_screen_callback_send_request_Button,
  connect_screen_callback_cancel_Button,
  connect_screen_callback_close_browser_label,
  connect_screen_reject_camera_permission_label,
  connect_screen_callback_button_visible,
  connect_screen_dropcall_button_visible,
  connect_screen_connect_info_visible,
  connect_screen_connect_button_auto_full_screen,
  connect_screen_connect_button_hidden,
  connect_screen_use_rectangle_button,
  connect_screen_connecting_status_label,
  connect_screen_connecting_status_audio,
  connect_screen_onhold_status_label,
  connect_screen_no_operator_available_label,
  connect_screen_info_position_top,
  connect_screen_info_position_width,
  connect_screen_invisible_status_label,
  connect_screen_connecting_label_color,
  connect_screen_connecting_label_size,
  connect_screen_connecting_label_background_color,
  connect_screen_voice_call_screen_title,
  connect_screen_wait_image,
  connect_screen_wait_video,
  connect_screen_wait_background_color,
  connect_screen_wait_screen_embedded_url,
  connect_screen_wait_screen_embedded_title,
  connect_screen_wait_screen_embedded_style,
  connect_screen_auto_connect,
  connect_screen_auto_leave,
  connect_screen_auto_answer,
  connect_screen_touchless,
  connect_screen_touchless_message,
  connect_screen_touchless_detection,
  connect_screen_touchless_detection_ratio,
  connect_screen_touchless_detection_movement,
  connect_screen_touchless_detection_confidence,
  connect_screen_touchless_autodrop,
  connect_screen_touchless_autodrop_message,
  connect_screen_mobile_footer,
  video_screen_enabled,
  video_screen_pip_position_fixed,
  video_screen_font_color,
  video_screen_background_color,
  video_screen_data_title,
  video_screen_show_names,
  video_screen_mic_mute_warning_message,
  loopback_panel_enabled,
  settings_panel_enabled,
  settings_panel_title,
  settings_panel_relay_candidate_only,
  settings_panel_disable_audio_only,
  settings_panel_audio_codecs,
  settings_panel_audio_toggle,
  settings_panel_primary_video_codecs,
  settings_panel_secondary_video_codecs,
  settings_panel_video_toggle,
  settings_panel_microphone_test_toggle,
  settings_panel_speaker_test_toggle,
  settings_panel_default_volume,
  settings_panel_camera_rotation,
  settings_panel_add_secondary_camera_text,
  settings_panel_remove_secondary_camera_text,
  settings_panel_resolution_title,
  settings_panel_resolution_auto_title,
  settings_panel_resolution_options,
  settings_panel_resolution_default_pc_value,
  settings_panel_resolution_default_mobile_value,
  settings_panel_resolution_default_pc_secondary_value,
  settings_panel_resolution_default_mobile_secondary_value,
  settings_panel_presentation_sharpness_first_resolution,
  settings_panel_presentation_sharpness_first_framerate,
  settings_panel_presentation_motion_first_resolution,
  settings_panel_presentation_motion_first_framerate,
  settings_panel_bandwidth_title,
  settings_panel_bandwidth_auto_title,
  settings_panel_bandwidth_options,
  settings_panel_bandwidth_default_pc_value,
  settings_panel_bandwidth_default_mobile_value,
  settings_panel_device_names,
  settings_panel_font_color,
  settings_panel_background_color,
  settings_panel_top,
  settings_panel_bottom,
  settings_panel_left,
  settings_panel_right,
  settings_panel_width,
  settings_panel_run_tester_on_first_visit,
  settings_panel_show_customized_video_muted_placeholder,
  settings_panel_avatar_image_src,
  settings_panel_video_aspect_title,
  settings_panel_virtual_background_toggle,
  settings_panel_virtual_background_method,
  settings_panel_video_aspect_default,
  settings_panel_troubleshoot_text,
  diagnostic_panel_enabled,
  diagnostic_panel_on_screen_inbound_quality_guages,
  diagnostic_panel_on_screen_outbound_quality_guages,
  diagnostic_panel_info_dialog_quality_gauges,
  diagnostic_panel_watchrtc_active,
  diagnostic_panel_watchrtc_api_key,
  chat_panel_enabled,
  chat_panel_chat_with_api_user,
  chat_panel_chat_with_api_user_title,
  chat_panel_disable_chat_with_web_user,
  chat_panel_title,
  chat_panel_exit_button,
  chat_panel_edit_button,
  chat_panel_done_button,
  chat_panel_back_button,
  chat_panel_start_chat_button,
  chat_panel_send_message_title,
  chat_panel_font_family,
  chat_panel_font_size,
  chat_panel_font_color,
  chat_panel_background_color,
  chat_panel_top,
  chat_panel_bottom,
  chat_panel_left,
  chat_panel_right,
  chat_panel_width,
  chat_panel_add_participant_button_tooltip,
  chat_panel_add_participant_button_text,
  chat_panel_start_group_chat_button,
  chat_panel_chat_disabled_message,
  chat_panel_chat_audio_alert_option,
  chat_panel_chat_audio_alert_type,
  chat_panel_chat_audio_alert_frequency,
  chat_panel_chat_audio_alert_duration,
  chat_panel_chat_audio_alert_interval,
  chat_panel_silence_chat_audio_alerts,
  chat_panel_silence_chat_audio_alerts_host_only,
  content_share_panel_enabled,
  content_share_panel_title,
  content_share_panel_exit_button,
  content_share_panel_cursor_image,
  content_share_panel_cursor_px,
  participant_panel_enabled,
  participant_panel_title_color,
  participant_panel_exit_button,
  participant_panel_connect_button,
  participant_panel_disconnect_button,
  participant_panel_callback_confirm_button,
  participant_panel_callback_handle_external_button,
  participant_panel_start_monitor_button,
  participant_panel_whisper_button,
  participant_panel_exit_monitor_button,
  participant_panel_select_button,
  participant_panel_unselect_button,
  participant_panel_hold_button,
  participant_panel_resume_button,
  participant_panel_take_over_button,
  participant_panel_transfer_to_queue_button_disabled,
  participant_panel_transfer_to_queue_button,
  participant_panel_chat_with_conjunction_text,
  participant_panel_hold_conference_button,
  participant_panel_resume_conference_button,
  participant_panel_participant_status,
  participant_panel_list_type,
  participant_panel_list_sort_by_enter_time,
  participant_panel_list_show_offline_endpoints,
  participant_panel_list_show_offline_users,
  participant_panel_font_color,
  participant_panel_background_color,
  participant_panel_top,
  participant_panel_bottom,
  participant_panel_left,
  participant_panel_right,
  participant_panel_width,
  participant_panel_max_participants_to_connect,
  participant_panel_disable_operator_guest_connect,
  participant_panel_hide_guests_with_device_problems,
  presence_status_enabled,
  presence_status_offline,
  presence_status_online,
  presence_status_alone_in_conf,
  presence_status_available,
  presence_status_busy,
  presence_status_invisible,
  presence_status_away,
  presence_status_work,
  presence_status_connecting,
  presence_status_ringing,
  presence_status_onhold,
  presence_status_ready,
  presence_status_ready_timelapse,
  presence_status_custom1,
  presence_status_custom2,
  presence_status_custom3,
  presence_status_custom4,
  share_screen_panel_enabled,
  share_folder_panel_enabled,
  share_folder_panel_title,
  share_folder_panel_conference_files_title,
  share_folder_panel_remove_conf_text,
  share_folder_panel_request_file_text,
  share_folder_panel_request_all_text,
  share_folder_panel_local_files_title,
  share_folder_panel_remove_local_text,
  share_folder_panel_offer_file_text,
  share_folder_panel_load_file_text,
  share_folder_panel_file_sharing_status_idle,
  share_folder_panel_file_sharing_status_started,
  share_folder_panel_file_sharing_status_transmitting,
  share_folder_panel_file_sharing_status_finished,
  share_folder_panel_file_sharing_status_failed,
  share_folder_panel_autosave,
  record_panel_enabled,
  record_panel_title,
  record_panel_warning_message,
  record_panel_confirm_button,
  record_panel_exit_button,
  record_panel_auto_record,
  record_panel_send_to_ftp,
  record_panel_audio_mode,
  record_panel_video_mode,
  record_panel_audio_mode_text,
  record_panel_video_mode_text,
  record_panel_disabled_text,
  record_panel_local_only_text,
  record_panel_remote_only_text,
  record_panel_both_sides_text,
  record_panel_filename_pattern,
  snapshot_panel_enabled,
  snapshot_panel_video_mode,
  snapshot_panel_file_name_pattern,
  take_photo_enabled,
  take_photo_resolution,
  take_photo_framerate,
  map_panel_enabled,
  map_panel_hide_by_default,
  map_panel_access_geo_title,
  map_panel_default_latitude,
  map_panel_default_longitude,
  map_panel_default_zoom,
  notepad_enabled,
  notepad_template,
  ring_tone_panel_enabled,
  ring_tone_panel_title,
  ring_tone_panel_confirm_button,
  ring_tone_panel_confirm_voice_button,
  ring_tone_panel_exit_button,
  ring_tone_panel_duration,
  lock_panel_enabled,
  lock_panel_auto_lock,
  lock_panel_modalTitle,
  lock_panel_exitText,
  lock_panel_submitText,
  lock_panel_submitBgColor,
  lock_panel_submitTextColor,
  lock_panel_backgroundColor,
  lock_panel_textColor,
  lock_panel_entryPlaceholder,
  footer_enabled,
  footer_font_color,
  footer_font_family,
  footer_background_color,
  footer_border_color,
  footer_copyright,
  footer_menu,
  footer_social_menu,
  reporting_enabled,
  reporting_max_preview,
  reporting_max_download,
  error_message_enabled,
  error_message_list,
  ice_config_enabled,
  ice_config_list,
  twilio_enabled,
  twilio_number,
  twilio_allow_dial_out,
  twilio_error_message,
  twilio_gather_active,
  twilio_gather_timeout,
  twilio_gather_message,
  twilio_gather_message_delay,
  twilio_gather_pin_length,
  custom_css_enabled,
  custom_css_apply_ada_styles,
  custom_css_content,
  rtc_config_enabled,
  rtc_config_bundle_policy
}
