import { IEndpoint, StatusReason } from '../endpoint/endpoint.interface';
import { Endpoint } from '../endpoint/endpoint';
import { from } from 'jssm';
import { IConferenceRef } from '../conference/conference.interface';
import * as _ from "lodash";

export enum ClientState {
    offline = 1,
    unavailable,
    unavailable_ringing, // No plan yet but could ring while unavailable
    available,
    available_ringing,
    ask_to_answer_queue,
    ask_to_answer_transfer, // direct transfer to agent.
    conferencing_intent,
    conferencing_intent_pending_invite_reply,
    check_confs,
    check_confs_drop_req,
    conferencing_wait_in_line,
    conferencing_wait_head_of_line,
    conferencing_wait_ringing,
    conferencing_intent_pending_invite_reply_drop_req,
    conferencing,
    conferencing_drop_req,
    transfer_pending // guests direct transfer is pending (awaiting dest agent confirmation)
}

declare type HookHandler<mDT> = (hook_context: HookContext<mDT>) => HookResult<mDT>;
export declare type HookContext<mDT> = {
    data: mDT;
    next_data: mDT;
};
declare type HookResult<mDT> = true | false | undefined | void | HookComplexResult<mDT>;
declare type HookComplexResult<mDT> = {
    pass: boolean;
    state?: StateType;
    data?: mDT;
    next_data?: mDT;
};
declare type StateType = string;

export enum ClientEvent {
    gone = 1,
    ready,
    unready,
    await_invite,
    request_conf_join,
    invitation_sent, // We are inviting another party into our conference.
    queue_chosen, // We are being asked to answer a guest in the queue.
    reject_answer_ready, // Reject a request to answer and return to the ready state
    reject_answer_unready, // Reject a request to answer and enter the unready state
    invitation_complete, // Both accepted and rejected
    reject_invitation, // Inviter rescinds their invitation to the invite. 
    accept_invitation,
    check_conferencing, // Check which conferences we are active in to make a state determination
    check_recess, // Determine if we should enter the Recess (unavailable) state
    removed_from_conf,
    disbanding,
    conf_data_change, // our relationship within the conference may have changed (on hold or transfer)
    attempt_direct_transfer, // an agent has initiated a direct transfer on us (guest).
    ask_to_answer_transfer, // another agent has requested we (agent) receive a transferred guest.
    confirm_transfer, // the target agent has confirmed the transfer and will allow it to proceed.
    add_to_conf // add an endpoint directly into the conference (when confirmation and ringing is not required)
}

export type ClientContextData = {
    clientSm: ClientSm,
    reason?: StatusReason,
    preserveStateEntryTime? : boolean,
    queueMatch?:
    {
        guestEp?: IEndpoint,
        agentEp?: IEndpoint, 
        timeout?: number // how long we allow the call to be unanswered
    }
    invitation?: {
        inviterEp?: IEndpoint,
        inviteeEp?: IEndpoint,
        conferenceId?: string,
        accepted?: boolean,
        handleExternally?: boolean // handle this invitation outside of the VCC system.
        timeout?: number // how long we allow the invitation to remain unanswered.
    },
    transfer?: {
        srcConfId?: string, // the current conference the guest resides in
        target: IEndpoint, // the endpoint to transfer
        queueName?: string // for transfer to queue
        targetConfId?: string // for direct to agent transfer
    },
    hold?: {
        conference: IConferenceRef,
        target: IEndpoint,
        onHold: boolean;
    },
    enterConference: { // enter directly into a conf
        conference: IConferenceRef,
        target: IEndpoint,
        // whether we are taking over this conference when we join it...
        takeOver?: boolean
    },
};

// Cleanup a context so we can log it without spamming
export function slimifyContext(contextData: ClientContextData) : any{
    let slimData: any = _.cloneDeep(contextData);
    // Replace client-sm data with voice EP flag.
    if (slimData?.clientSm) {
        delete slimData["clientSm"];
        if (contextData?.clientSm?.isVoiceEp) {
            slimData["isVoiceEp"] = true;
        }
    }
    // String version of reason
    if (slimData?.reason) {
        slimData.reason = StatusReason[slimData.reason];
    }
    // Convert queue match endpoints to refs
    if (slimData?.queueMatch?.agentEp) {
        slimData.queueMatch.agentEp = JSON.stringify(Endpoint.toRef(slimData.queueMatch.agentEp));
    }
    if (slimData?.queueMatch?.guestEp) {
        slimData.queueMatch.guestEp = JSON.stringify(Endpoint.toRef(slimData.queueMatch.guestEp));
    }

    // Convert invitation endopints to refs 
    if (slimData?.invitation?.inviterEp) {
        slimData.invitation.inviterEp = JSON.stringify(Endpoint.toRef(slimData.invitation.inviterEp));
    }
    if (slimData?.invitation?.inviteeEp) {
        slimData.invitation.inviteeEp = JSON.stringify(Endpoint.toRef(slimData.invitation.inviteeEp));
    }

    // Convert transfer target to ref
    if (slimData?.transfer?.target) {
        slimData.transfer.target = JSON.stringify(Endpoint.toRef(slimData.transfer.target));
    }

    // Convert hold target to ref
    if (slimData?.hold?.target) {
        slimData.hold.target = JSON.stringify(Endpoint.toRef(slimData.hold.target));
    }
    if (slimData?.hold?.conference) {
        slimData.hold.conference = JSON.stringify(slimData.hold.conference);
    }

    // Convert enter conference target to ref
    if (slimData?.enterConference?.target) {
        slimData.enterConference.target = JSON.stringify(Endpoint.toRef(slimData.enterConference.target));
    }
    if (slimData?.enterConference?.conference) {
        slimData.enterConference.conference = JSON.stringify(slimData.enterConference.conference);
    }

    return slimData;
}

export class ClientSm {
    // Slimmed-down graphviz edge list with consistent events from labels
    private static MACHINE_DEF: string = `
    offline 'ready' -> available;
    offline 'unready' -> unavailable;

    unavailable 'disbanding' -> conferencing_drop_req;
    unavailable 'ready' -> available;
    unavailable 'await_invite' -> conferencing_wait_in_line;
    unavailable 'invitation_sent' -> unavailable_ringing;
    unavailable 'ask_to_answer_transfer' -> ask_to_answer_transfer;
    unavailable 'add_to_conf' -> check_confs;
    unavailable 'unready' -> unavailable;
    unavailable 'request_conf_join' -> conferencing_intent;

    unavailable_ringing 'accept_invitation' -> conferencing;
    unavailable_ringing 'reject_invitation' -> unavailable;
    unavailable_ringing 'disbanding' -> conferencing_drop_req;

    available 'disbanding' -> conferencing_drop_req;
    available 'unready' -> unavailable;
    available 'queue_chosen' -> ask_to_answer_queue;
    available 'await_invite' -> conferencing_wait_in_line;
    available 'invitation_sent' -> available_ringing;
    available 'request_conf_join' -> conferencing_intent;
    available 'add_to_conf' -> check_confs;
    available 'ask_to_answer_transfer' -> ask_to_answer_transfer;
    available 'ready' -> available;

    available_ringing 'accept_invitation' -> conferencing;
    available_ringing 'reject_invitation' -> available;
    available_ringing 'disbanding' -> conferencing_drop_req;

    ask_to_answer_queue 'request_conf_join' -> conferencing_intent;
    ask_to_answer_queue 'reject_answer_ready' -> available;
    ask_to_answer_queue 'reject_answer_unready' -> unavailable;
    ask_to_answer_queue 'disbanding' -> conferencing_drop_req;
    ask_to_answer_queue 'invitation_complete' -> check_confs;

    ask_to_answer_transfer 'confirm_transfer' -> check_confs;
    ask_to_answer_transfer 'reject_answer_ready' -> available;
    ask_to_answer_transfer 'reject_answer_unready' -> unavailable;
    ask_to_answer_transfer 'disbanding' -> conferencing_drop_req;

    conferencing_intent 'invitation_sent' -> conferencing_intent_pending_invite_reply;
    conferencing_intent 'invitation_complete' -> check_confs;
    conferencing_intent 'disbanding' -> conferencing_drop_req;

    conferencing_intent_pending_invite_reply 'invitation_complete' -> check_confs;
    conferencing_intent_pending_invite_reply 'disbanding' -> conferencing_intent_pending_invite_reply_drop_req;

    check_confs 'check_conferencing' -> conferencing;
    check_confs 'check_recess' -> unavailable;
    check_confs 'disbanding' -> check_confs_drop_req;

    check_confs_drop_req 'check_conferencing' -> conferencing_drop_req;
    check_confs_drop_req 'check_recess' -> unavailable;

    conferencing_wait_in_line 'queue_chosen' -> conferencing_wait_head_of_line;
    conferencing_wait_in_line 'invitation_sent' -> conferencing_wait_ringing;
    conferencing_wait_in_line 'disbanding' -> conferencing_drop_req;
    conferencing_wait_in_line 'ready' -> available;
    conferencing_wait_in_line 'unready' -> unavailable;

    conferencing_wait_head_of_line 'invitation_sent' -> conferencing_wait_ringing;
    conferencing_wait_head_of_line 'await_invite' -> conferencing_wait_in_line;
    conferencing_wait_head_of_line 'ready' -> available;
    conferencing_wait_head_of_line 'unready' -> unavailable;
    conferencing_wait_head_of_line 'disbanding' -> conferencing_drop_req;

    conferencing_wait_ringing 'accept_invitation' -> conferencing;
    conferencing_wait_ringing 'await_invite' -> conferencing_wait_in_line;
    conferencing_wait_ringing 'ready' -> available;
    conferencing_wait_ringing 'unready' -> unavailable;
    conferencing_wait_ringing 'disbanding' -> conferencing_drop_req;
    
    conferencing 'disbanding' -> conferencing_drop_req;
    conferencing 'conf_data_change' -> check_confs;
    conferencing 'request_conf_join' -> conferencing_intent;
    conferencing 'attempt_direct_transfer' -> transfer_pending;

    transfer_pending 'confirm_transfer' -> check_confs;
    transfer_pending 'disbanding' -> conferencing_drop_req;
    
    conferencing_intent_pending_invite_reply_drop_req 'invitation_complete' -> check_confs_drop_req;
    conferencing_intent_pending_invite_reply_drop_req 'invitation_rejected_recess' -> unavailable;

    conferencing_drop_req 'disbanding' -> conferencing_drop_req;
    conferencing_drop_req 'invitation_complete_conferencing' -> conferencing;
    conferencing_drop_req 'removed_from_conf' -> check_confs;    
    conferencing_drop_req 'gone' -> offline;
    `;

    // The actual jssm state machine instance.
    private _machine;

    private _clientId: string;
    private _isVoiceEp: boolean;
    private changeNotify: HookHandler<ClientContextData>;
    private _lastSetContextData: ClientContextData;

    get clientId() : Readonly<string> {
        return this._clientId;
    }

    get state(): Readonly<ClientState> {
        return  ClientState[this._machine.state() as keyof typeof ClientState];
    }

    get context(): ClientContextData {
        return this._lastSetContextData;
    }

    get isVoiceEp(): boolean {
        return this._isVoiceEp;
    }

    public setVoiceEp(voiceEp : boolean) {
        this._isVoiceEp = true;
    }

    /**
     * Create a new state machine and possibly set its state.
     * @param clientRtcId The ID of the client.
     * @param isVoiceEp This is for voice endpoint
     * @param initState The state to force the machine into in case of loading existing client data.
     */
    constructor(clientRtcId: string, isVoiceEp: boolean, initState: ClientState = ClientState.offline) {
        this._clientId = clientRtcId;
        this._isVoiceEp = isVoiceEp;
        this._machine = from(ClientSm.MACHINE_DEF, {initial_state: ClientState[initState], start_states_no_enforce:true});
        this._machine.post_hook_any_transition(this.updateContextCopy.bind(this));
    }

    registerChangeNotify(callback: HookHandler<ClientContextData>) {
        this.changeNotify = callback;
    }

    updateContextCopy(context: HookContext<ClientContextData>) {
        this._lastSetContextData = _.cloneDeep(context.next_data as any);
        if (this.changeNotify) {
            this.changeNotify(context);
        }
    }

    registerStateEntryNotify(to: ClientState, callback: HookHandler<ClientContextData>) {
        this._machine.post_hook_entry(ClientState[to], callback);
    }

    registerStateExitNotify(from: ClientState, callback: HookHandler<ClientContextData>) {
        this._machine.post_hook_exit(ClientState[from], callback);
    }

    // Probably want to check 'gone' and if not, run a 'disbanding' instead.
    check(event: Readonly<ClientEvent>): Readonly<boolean> {
        return this._machine.valid_action(ClientEvent[event])
    }

    event(event: Readonly<ClientEvent>, contextData?: Partial<ClientContextData>): Readonly<boolean> {
        if (!contextData) {
            contextData = {clientSm: this};
        } else {
            contextData.clientSm = this;
        }
        return this._machine.action(ClientEvent[event], contextData as ClientContextData);
    }
}
