/**
 * Copyright Compunetix Incorporated 2018
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  kbender, lcheng
 */

import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";

import { UserManagementPageComponent } from "./user-management.component";
import { UserProfileModule } from "../user-profile/user-profile.module";
import { SharedModule } from "../shared/shared.module";
import { LayoutModule } from "../layout/layout.module";

@NgModule({
  imports: [BrowserModule, NgxDatatableModule, UserProfileModule, SharedModule, LayoutModule],
  declarations: [UserManagementPageComponent],
  exports: [UserManagementPageComponent]
})
export class UserManagementModule {}
