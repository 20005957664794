/**
 * Copyright Compunetix Incorporated 2017-2018
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { NgModule } from "@angular/core";

import { SortByPipe } from "./pipes/sort-by.pipe";
import { FindPipe } from "./pipes/find.pipe";
import { KeysPipe } from "./pipes/keys.pipe";
import { TruncatePipe } from "./pipes/truncate.pipe";
import { TruncateLeftPipe } from "./pipes/truncate-left.pipe";

import { TimeService } from "./services/time.service";

import { TreeViewModule } from "./components/tree-view/tree-view.module";
import { VolumeMeterModule } from "./components/volume-meter/volume-meter.module";
import { SafeHtmlPipe } from "./pipes/safe-html.pipe";
import { StatusStylePipe } from "./pipes/endpoint-status-style.pipe";
import { ReportService } from "../shared/services/report.service";
import { TrustHtmlPipe } from "./pipes/trust-html.pipe";
import { SignalBarsComponent } from "./components/signal-bars-indicator/signal-bars.component";
import { SignalBarsModule } from "./components/signal-bars-indicator/signal-bars.module";
import { DomChangeDirective } from "./directives/dom-change.directive";
import { HoursOfOperationModule } from "./components/hours-of-operation/hours-of-operation.module";

@NgModule({
  imports: [TreeViewModule, VolumeMeterModule, SignalBarsModule, DomChangeDirective, HoursOfOperationModule],
  declarations: [SortByPipe, FindPipe, KeysPipe, TruncatePipe, TruncateLeftPipe, SafeHtmlPipe, TrustHtmlPipe, StatusStylePipe],
  providers: [TimeService, SortByPipe, SafeHtmlPipe, TrustHtmlPipe, ReportService, SignalBarsComponent, DomChangeDirective],
  exports: [
    SortByPipe,
    FindPipe,
    KeysPipe,
    TruncatePipe,
    TruncateLeftPipe,
    TreeViewModule,
    VolumeMeterModule,
    SafeHtmlPipe,
    TrustHtmlPipe,
    StatusStylePipe,
    SignalBarsComponent,
    DomChangeDirective,
    HoursOfOperationModule
  ]
})

export class SharedModule {}