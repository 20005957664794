/**
 * Copyright Compunetix Incorporated 2016-2018
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { IUser } from "../user/user.interface";

/**
 * global util methods delegate
 */
export class GlobalService {
  /**
   * session user cookie key
   */
  static session_user_cookie_name = "CompanionWebSessionUser";

  /**
   * default local ip address
   */
  static localIpAddress = "0.0.0.0";

  /**
   * browser ua
   */
  static ua: string;

  /**
   */
  public static getCurrentUA(): string {
    this.ua = navigator.userAgent.toLowerCase();
    return this.ua;
  }

  /**
   * save session user info into cookie
   */
  public static setSessionUser(sessionUser: IUser, days: number = 7): void {
    let userClone = _.clone(sessionUser);
    const userToStore: IUser = {
      username: userClone.username,
      preferredBandwidth: userClone.preferredBandwidth,
      helperFinished: userClone.helperFinished,
      preferredPrimaryResolution: userClone.preferredPrimaryResolution,
      preferredSecondaryResolution: userClone.preferredSecondaryResolution,
      preferredPrimaryCameraDeviceId: userClone.preferredPrimaryCameraDeviceId,
      preferredPrimaryCameraDeviceLabel: userClone.preferredPrimaryCameraDeviceLabel,
      preferredPrimaryCameraRotation: userClone.preferredPrimaryCameraRotation,
      preferredSecondaryCameraDeviceId: userClone.preferredSecondaryCameraDeviceId,
      preferredSecondaryCameraDeviceLabel: userClone.preferredSecondaryCameraDeviceLabel,
      preferredMicrophoneDeviceId: userClone.preferredMicrophoneDeviceId,
      preferredSpeakerDeviceId: userClone.preferredSpeakerDeviceId,
      preferredLanguage: userClone.preferredLanguage,
      preferredAudioMute: userClone.preferredAudioMute,
      preferredVideoMute: userClone.preferredVideoMute,
      preferredCalendarView: userClone.preferredCalendarView,
      preferredVolume: userClone.preferredVolume,
      preferredVideoAspect: userClone.preferredVideoAspect,
      preferredVideoMutedPlaceholder: userClone.preferredVideoMutedPlaceholder,
      preferredVirtualBackgroundImage: userClone.preferredVirtualBackgroundImage,
      preferredVirtualBackgroundEnabled: userClone.preferredVirtualBackgroundEnabled,
      preferredQualitySetting: userClone.preferredQualitySetting,
      preferredBackgroundBlurAmount: userClone.preferredBackgroundBlurAmount,
      preferredEdgeBlurAmount: userClone.preferredEdgeBlurAmount,
      preferredCropperPosition: userClone.preferredCropperPosition,
      preferredCropperEnabled: userClone.preferredCropperEnabled
    };

    localStorage.setItem(GlobalService.session_user_cookie_name, JSON.stringify(userToStore));
  }

  /**
   * get session user info from cookie
   */
  public static getSessionUser(): IUser {
    let sessionUser = localStorage.getItem(GlobalService.session_user_cookie_name) 
      ? JSON.parse(localStorage.getItem(GlobalService.session_user_cookie_name)) : {};
    return sessionUser;
  }

  /**
   * get local ip address
   */
  public static getLocalIpAddress(): void {
    var RTCPeerConnection = (window as any).webkitRTCPeerConnection || (window as any).mozRTCPeerConnection;

    if (RTCPeerConnection) {
      var rtc = new RTCPeerConnection({ iceServers: [] });
      rtc.createDataChannel("", { reliable: false });

      rtc.onicecandidate = (evt: any) => {
        if (evt.candidate) {
          GlobalService.localIpAddress = GlobalService.getIpFromSDP("a=" + evt.candidate.candidate);
        }
      };
      rtc.createOffer(
        (offerDesc: any) => {
          GlobalService.localIpAddress = GlobalService.getIpFromSDP(offerDesc.sdp);
          rtc.setLocalDescription(offerDesc);
        },
        (e: any) => {
          console.warn("offer failed", e);
        }
      );
    }
  }

  /**
   * get ip address from sdp
   */
  private static getIpFromSDP(sdp: string): string {
    var ipAddress = "0.0.0.0";
    sdp.split("\r\n").forEach((line: any) => {
      if (line.indexOf("a=candidate") > -1) {
        var parts = line.split(" "),
          addr = parts[4],
          type = parts[7];
        if (type === "host") {
          ipAddress = addr;
        }
      } else if (line.indexOf("c=") > -1) {
        var parts = line.split(" "),
          addr = parts[2];
        ipAddress = addr;
      }
    });
    return ipAddress;
  }
}
