/**
 * Copyright Compunetix Incorporated 2017-2018
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { NgModule, NO_ERRORS_SCHEMA } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule } from "@angular/forms";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { AppComponent } from "./app.component";
import { PageNotFoundComponent } from "./page-not-found.component";

import { AppRoutingModule } from "./app-routing.module";
import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { AppService } from "./app.service";
import { ConfigModule } from "./config/config.module";
import { UserManagementModule } from "./user-management/user-management.module";
import { RoleManagementModule } from "./role-management/role-management.module";
import { GroupManagementModule } from "./group-management/group-management.module";
import { LoginModule } from "./login/login.module";
import { AlertModule } from "./alert/alert.module";
import { BrowserNotSupportedComponent } from "./browser-not-supported.component";
import { NotificationModule } from "./notification/notification.module";
import { Store } from "./Store/store.service";
import { ClientLogService } from "./shared/services/client-log.service";
import { SharedModule } from "./shared/shared.module";
import { HelperService } from "./helper/helper.service";
import { PasswordResetModule } from "./password-reset/password-reset.module";
import { LayoutModule } from "./layout/layout.module";
import { RestOptions, RestService } from "./shared/services/rest.service";
import { LocalizationService } from "./localization/localization.service";
import { NavBarService } from "./layout/nav-bar/nav-bar.service";
import { UserManagementService } from "./user-management/user-management.service";

@NgModule({ declarations: [AppComponent, PageNotFoundComponent, BrowserNotSupportedComponent],
    bootstrap: [AppComponent], // why can't i provide call center service in base module.
    schemas: [NO_ERRORS_SCHEMA], 
    imports: [BrowserModule,
        FormsModule,
        AppRoutingModule,
        LayoutModule,
        ConfigModule,
        UserManagementModule,
        RoleManagementModule,
        GroupManagementModule,
        LoginModule,
        AlertModule,
        NotificationModule,
        //HelperModule,
        BrowserAnimationsModule,
        BsDropdownModule.forRoot(),
        PasswordResetModule,
        SharedModule], 
    providers: [
        RestService, 
        UserManagementService, 
        NavBarService, 
        RestOptions, 
        LocalizationService, 
        AppService, 
        Store, 
        ClientLogService, 
        SharedModule, 
        HelperService, 
        provideHttpClient(withInterceptorsFromDi())] })
export class AppModule {}
