<!--
    Copyright Compunetix Incorporated 2017-2018
            All rights reserved
    This document and all information and ideas contained within are the
    property of Compunetix Incorporated and are confidential.

    Neither this document nor any part nor any information contained in it may
    be disclosed or furnished to others without the prior written consent of:
            Compunetix Incorporated
            2420 Mosside Blvd
            Monroeville, PA 15146
            http://www.compunetix.com

    Author:  lcheng, kbender
-->

<div
  class="container text-center"
  *ngIf="localizationData.loginPanel"
  [style.backgroundColor]="localizationData.loginPanel.backgroundColor"
  [ngStyle]="{'color': localizationData.loginPanel.fontColor}"
>
<h1
  class="text-center p-1"
  [ngStyle]="localizationData.loginPanel.fontColor ? {'color': localizationData.loginPanel.fontColor} : ''">
  {{localizationData.loginPanel.title}}
</h1>
  <div class="join-conference animated fadeIn offset-md-1 col-md-10">
  <div class="row">
    <div
      class="col-12"
      [hidden]="userService.currentUser.isAuthenticated"
    >
      <form
        [hidden]="recoveryInProgress"
        (ngSubmit)="onSubmit()"
        #LoginForm="ngForm"
        [attr.id]="'LoginForm'"
        autocomplete="off"
      >
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            required
            [(ngModel)]="userModel.username"
            ngControl="username"
            name="username"
            #username="ngModel"
            #usernameField
            [attr.placeholder]="localizationData.loginPanel?.usernameFieldTitle"
            [title]="localizationData.loginPanel?.usernameFieldTitle || 'Username'"
            autofocus
          >
          <div
            [hidden]="username.valid || username.pristine"
            class="alert alert-danger"
          >
            {{localizationData?.errorMessages?.['USERNAME_REQUIRED'] || 'Name is required'}}
          </div>
        </div>
        <div class="form-group">
          <input
            type="password"
            class="form-control"
            required
            [(ngModel)]="userModel.password"
            ngControl="password"
            name="password"
            #password="ngModel"
            [attr.placeholder]="localizationData.loginPanel?.passwordFieldTitle"
            [title]="localizationData.loginPanel?.passwordFieldTitle || 'Password'"
            autofocus
          >
          <div
            [hidden]="password.valid || password.pristine || submitted"
            class="alert alert-danger"
          >
            {{localizationData?.errorMessages?.['PASSWORD_REQUIRED'] || 'Password is required'}}
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <button
              type="submit"
              [title]="localizationData.loginPanel?.submitButtonText || 'Submit'"
              class="btn btn-primary text-uppercase full-width btn-lg"
              [disabled]="!LoginForm.form.valid"
              [style.backgroundColor]="localizationData.loginPanel?.submitButtonColor"
              [style.borderColor]="localizationData.loginPanel?.submitButtonColor"
              (mouseenter)="onMouseEnter($event)"
              (mouseleave)="onMouseLeave($event)"
              autofocus
            >
              {{localizationData.loginPanel?.submitButtonText || 'Submit'}}
            </button>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <p class="full-width text-center">
              <button
                class="btn btn-link text-color-inherit"
                [title]="localizationData.loginPanel?.forgotUsernameButtonText || 'Forgot My Username'"
                (click)="showForgotUsername()"
                [ngStyle]="{'color': localizationData.loginPanel?.fontColor}"
              >
                {{localizationData.loginPanel?.forgotUsernameButtonText || 'Forgot My Username'}}
              </button>
              <button
                class="btn btn-link text-color-inherit"
                [title]="localizationData.loginPanel?.forgotPasswordButtonText || 'Reset My Password'"
                (click)="showForgotPassword()"
                [ngStyle]="{'color': localizationData.loginPanel?.fontColor}"
              >
                {{localizationData.loginPanel?.forgotPasswordButtonText || 'Reset My Password'}}
              </button>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <p class="" *ngIf="localizationData.loginPanel?.loginWithOpenIDCUrl">
                <a
                  [attr.href]=''
                  (click)="loginOpenIDC()"
                  class="btn btn-secondary"
                >{{this.localizationData.loginPanel?.loginWithOpenIDCButton || 'Sign In with OpenID'}}</a>
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <p class="full-width text-center" *ngIf="localizationData.loginPanel?.helpMessage1">
              <label
                style="max-width:75%;"
                [innerHTML]="localizationData.loginPanel?.helpMessage1 | trustHtml"
              >
              </label>
            </p>
            <p class="full-width text-center" *ngIf="localizationData.loginPanel?.helpMessage2">
              <label
                style="max-width:75%;"
                [innerHTML]="localizationData?.loginPanel?.helpMessage2 | trustHtml"
              >
              </label>
            </p>
          </div>
        </div>
      </form>

      <form
        [hidden]="!recoveryInProgress"
        (ngSubmit)="forgotUsernameSubmitted()"
        #resetForm="ngForm"
        autocomplete="off"
      >
        <label [hidden]=!forgotUsername> {{localizationData.loginPanel?.forgotUsernameButtonText || 'Forgot My Username'}}
        </label>
        <label [hidden]=!resetPassword> {{localizationData.loginPanel?.forgotPasswordButtonText || 'Reset My Password'}}
        </label>

        <fieldset>
          {{localizationData.loginPanel?.emailFieldTitle || 'Recovery Email'}}:
          <input
            type="text"
            title="Email Address"
            class="form-control"
            id="emailAddress"
            placeholder="email address"
            [(ngModel)]="accountEmail"
            #emailAddress="ngModel"
            #emailAddressField
            name="emailAddress"
            required
            pattern="\S+@\S+\.\S+"
            autofocus
          >
          <div
            [hidden]="emailAddress.valid || emailAddress.pristine"
            class="alert alert-danger"
          >
            {{localizationData?.errorMessages?.['EMAIL_INVALID'] || 'Invalid email address.'}}
          </div>
        </fieldset>
        <button
          type="submit"
          [title]="localizationData.loginPanel?.sendButtonText || 'Send'"
          [disabled]="!resetForm.form.valid"
          class="btn"
        >{{localizationData.loginPanel?.sendButtonText || 'Send'}}</button>
        <button
          type="button"
          [title]="localizationData.loginPanel?.cancelButtonText || 'Cancel'"
          (click)="forgotUsernameCancelled()"
          class="btn"
        >{{localizationData.loginPanel?.cancelButtonText || 'Cancel'}}</button>
      </form>

    </div>
    <div
      [hidden]="!submitted"
      class="alert alert-info full-width"
    >
      <h2>
        {{localizationData?.errorMessages?.['WAIT'] || 'Please Wait...'}}
      </h2>
    </div>
    <div
      [hidden]="!loginFailed"
      class="alert alert-danger full-width"
    >
      <h2>
        {{localizationData?.errorMessages?.[errorMessage] || errorMessage}}
      </h2>
    </div>
  </div>
</div>
<h1 style="text-align: center" *ngIf="!isMobileApp">{{localizationData.loginPanel.desktopOnlyText}}</h1>